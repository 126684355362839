const createFaqs = () => {

    const faqs = document.querySelectorAll('.faqs-list__item');

    faqs.forEach(faq => {

        const trigger = faq.querySelector('.faqs-list__item__title');

        trigger.addEventListener('click', e => {
            e.preventDefault();
            faq.classList.toggle('open')
        })
    })

}

window.addEventListener('load', createFaqs);
