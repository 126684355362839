export const contain = (child, container, defaultY, offset = 'center' || 0) => {

    const style = window.getComputedStyle(child);
    const fixed = style.position === 'fixed';
    const transformCSS = new WebKitCSSMatrix(style.transform);
    const yAdjustment = transformCSS.m42;

    const startingAdj = offset === 'center' ?
        container.offsetHeight - window.scrollY - (child.offsetHeight + yAdjustment) - (window.innerHeight - child.offsetHeight)/2 :
        container.offsetHeight - window.scrollY - (child.offsetHeight + yAdjustment) - offset;

    if(defaultY.endsWith('%') && fixed) {

        const percentageNumber = defaultY.slice(0,-1);
        const decimal = percentageNumber / 100;
        const percentageAdj = startingAdj - window.innerHeight * decimal;

        return percentageAdj < 0 ? `calc(${defaultY} - ${Math.abs(percentageAdj)}px)` : defaultY;

    } else if(defaultY.endsWith('px') && fixed) {

        const pixelNumber = defaultY.slice(0,-2);
        const pixelAdj = startingAdj - pixelNumber

        return pixelAdj < 0 ? (pixelNumber - Math.abs(pixelAdj)) + 'px' : defaultY;

    } else {
        return 'auto';
    }

}

export const customEvents = {
    questionChange: new Event("questionChange")
}

export const formatNumber = string => string.split('').reverse().reduce((total, n, i) => {
    const sep = (i > 1 && i % 3 === 0) ? ',' : '';
    return n + sep + total
}, '')

export const cleanMoneyString = string => string.replace(/\./g,'').replace(/,/g,'').replace(/^0+/, '').replace(/£/g,'');

export const slideUp = (target, duration=500) => {

    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout( () => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
        //alert("!");
    }, duration);
}

export const slideDown = (target, duration=500) => {

    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;
    if (display === 'none') display = 'block';
    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout( () => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
    }, duration);
}
