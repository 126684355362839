import {slideDown, slideUp} from "./Utils";

const createToolTips = () => {

    const toolTips = document.querySelectorAll('.input-group-tooltip')


    toolTips.forEach(tooltip => {

        const title = tooltip.querySelector('.input-group-tooltip__title')
        const detail = tooltip.querySelector('.input-group-tooltip__detail')
        const closeBtn = tooltip.querySelector('.input-group-tooltip__detail__close')

        title.addEventListener('click', e => {
            e.preventDefault();

            if(detail.classList.contains("open")) {
                slideUp(detail)
                detail.classList.remove('open')
            } else {
                slideDown(detail)
                detail.classList.add('open')
            }

        })

        detail.addEventListener('click', e => {
            e.preventDefault();
            slideUp(detail)
            detail.classList.remove('open')
        })

        if(closeBtn) {
            closeBtn.addEventListener('click', e => {
                e.preventDefault();
                slideUp(detail)
                detail.classList.remove('open')
            })
        }

    })

}


window.addEventListener('load', createToolTips);
