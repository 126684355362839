let lastScrollTop = 0;
const toggleHeader = () => {

    const header = document.getElementById('mainHeader');
    if(!header) return;

    const minScroll = 5;
    const pixelStart = 100;
    const scroll = window.scrollY;

    // Minimal scroll block
    if (Math.abs(lastScrollTop - scroll) <= minScroll) return;

    if(scroll > lastScrollTop && scroll > pixelStart) {
        header.classList.add('main-header--hide')
    } else {
        header.classList.remove('main-header--hide')
    }

    lastScrollTop = scroll;
}
["load", "scroll"].forEach(function (evt) {
    window.addEventListener(evt, toggleHeader);
});
