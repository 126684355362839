import $ from "jquery";
require('jquery-mask-plugin')

const createInputMasks = () => {

    const inputMasks = document.querySelectorAll('.money-mask')

    inputMasks.forEach(input => {
        $(input).mask("000,000,000,000,000,000", {
            reverse: true
        });
    })
}

window.addEventListener('load', createInputMasks);
