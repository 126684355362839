const setupScrollLinks = () => {
    const scrollLinks = document.querySelectorAll('.scroll-to');

    scrollLinks.forEach(link => link.addEventListener('click', e => {
        e.preventDefault();
        
        const href = link.getAttribute('href');
        const target = document.querySelector(href);

        if(!target) {
            return;
        }

        target.scrollIntoView({ behavior: "smooth", block: "start" });
    }));
};

window.addEventListener('load', setupScrollLinks);
