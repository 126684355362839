const createToggles = () => {

    const toggles = document.querySelectorAll('.input-toggle');

    toggles.forEach(toggle => {

        const input = toggle.querySelector('input');
        const button = toggle.querySelector('.input-toggle__trigger');
        const label = toggle.querySelector('label');

        if (!button || !input) return;

        if(input.value.toLowerCase() === 'true') {
            button.classList.add('active');
        }

        [button, label].forEach(trigger => {
            trigger.addEventListener('click', () => {

                button.classList.toggle('active');

                if (input.value === '' || input.value.toLowerCase() === 'false') {
                    input.value = 'True';
                }
                else {
                    input.value = 'False';
                }
            });
        })


    });
};

window.addEventListener('load', createToggles);
