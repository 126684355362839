var dropdownOpen = false;
const createDropdowns = () => {

    const dropdowns = document.querySelectorAll('.dropdown');

    dropdowns.forEach(dropdown => {
        const trigger = dropdown.querySelector('.btn')
        if(!trigger) return;

        trigger.addEventListener('click', e => {
            e.stopPropagation();
            if(dropdown.classList.contains('show')) {
                dropdown.classList.remove('show');
                dropdownOpen = false;
            } else {
                dropdown.classList.add('show');
                if(dropdownOpen) dropdownOpen.classList.remove('show');
                dropdownOpen = dropdown
            }
        }, true)
    })
}

window.addEventListener('DOMContentLoaded', createDropdowns);

window.addEventListener('click', () => {
    if (dropdownOpen){
        dropdownOpen.classList.remove('show');
        dropdownOpen = false;
    }
})
