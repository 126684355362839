const setCookieBanner = () => {

    const cookieBanner = document.getElementById('cookieBanner');

    if(!cookieBanner) return;

    const btn = cookieBanner.querySelector('.btn');

    if(!btn) return;

    btn.addEventListener('click', e => {
        e.preventDefault();
        
        acceptCookies('MortgagesDirectAcceptCookies');

        cookieBanner.style.display = 'none';
    });
}

function acceptCookies(cookieName) {
    createCookie(cookieName, '1', 365);
    $('#cookieBanner').remove();
};

function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    }
    else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
};

window.addEventListener('load', setCookieBanner);