import {contain} from "./Utils";

const createOverlay = () => {

    const overlays = document.querySelectorAll('.overlay');
    if(!overlays) return;

    window.addEventListener('scroll', e => {
        overlays.forEach(overlay => {

            const visible = getComputedStyle(overlay).display === 'block';
            const overlayInner = overlay.querySelector('.overlay__inner');
            const fullDetails = overlayInner.classList.contains('overlay__inner--details');
            if(!visible || fullDetails) return;



            // ****
            // fullDetails adjustment removed due to content being clipped
            // ****
            // const defaultY = fullDetails ? '50px' : '50%';
            // const offset = fullDetails ? '50' : 'center';
            // overlayInner.style.top = contain(overlayInner, overlayContainer, defaultY, offset);

            const overlayContainer = overlay.querySelector('.overlay__bg');

            overlayInner.style.top = contain(overlayInner, overlayContainer, '50%', 'center');

        })
    })
}

window.addEventListener('load', createOverlay);
