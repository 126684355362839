import {customEvents} from "./Utils";

const createQuestions = () => {

    const questions = document.querySelectorAll('.input-question');

    questions.forEach(question => {

        const input = question.querySelector('input')
        const answers = question.querySelectorAll('.input-question__option');
        const optional = question.classList.contains('input-question--optional');
        if (!answers || !input) return;

        answers.forEach(answer => {

            if(input.value === answer.dataset.questionValue) {
                answer.classList.add('active');
                answer.click();
            }

            answer.addEventListener('click', () => {
                const active = answer.classList.contains('active');
                answers.forEach(answer => answer.classList.remove('active'));
                if(active && optional) {
                    input.value = '';
                } else {
                    answer.classList.add('active')
                    input.value = answer.dataset.questionValue;
                }
                input.dispatchEvent(customEvents.questionChange)
            })
        })
    })

}

window.addEventListener('load', createQuestions);
