const setCtaBar = () => {

    let showing = false;
    const bar = document.querySelector('.cta-bar');
    const bloc = document.querySelector('.cta-bloc');
    const cookieBanner = document.getElementById('cookieBanner');

    if(!bar || !bloc) return;

    if(cookieBanner) {
        const cookieBtn = cookieBanner.querySelector('.btn');
        cookieBtn.addEventListener('click', () => {
            if(showing) {
                bar.style.transform = 'translateY(0)';
            }
        })
    }

    window.addEventListener('scroll', () => {

        const show = window.scrollY > bar.offsetHeight;
        const hide = bloc.getBoundingClientRect().y < window.innerHeight;
        const cookieBarShowing = cookieBanner ? window.getComputedStyle(cookieBanner).display.includes('flex','block') : false;
        const bottomAdjust = cookieBarShowing ? -cookieBanner.offsetHeight + 'px' : 0;

        if (show && !hide){
            bar.style.transform = `translateY(${bottomAdjust})`;
            showing = true;
        } else {
            bar.style.transform = 'translateY(100%)';
            showing = false;
        }

    })

}
window.addEventListener('load', setCtaBar);
