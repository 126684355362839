let filtersChanged = false;

const updateFilterChanged = bool => {

    filtersChanged = bool;
    checkFiltersPendingUpdate();
}

const updateResults = () => {
    
    const btnUpdateFilters = document.getElementById('updateFilters');
    btnUpdateFilters.click();
}

const checkFiltersPendingUpdate = () => {

    const makingChanges = document.getElementById('makingChanges');
    makingChanges.style.display = filtersChanged ? 'block' : 'none';
}

const createFilterToggles = () => {

    const filter = document.querySelector('.results-sidebar__filters');
    const showFiltersBtn = document.getElementById('showFiltersBtn');
    const hideFiltersBtn = document.getElementById('hideFiltersBtn');

    if(!filter || !showFiltersBtn || !hideFiltersBtn) return;

    // Triggers by class
    const editFilters = document.querySelectorAll('.editFilters');
    const hideFilters = document.querySelectorAll('.hideFilters');

    const showAllFilters = e => {
        e.preventDefault();
        showFiltersBtn.classList.add('hide')
        filter.classList.add('show-all')
        checkFiltersPendingUpdate()
    };

    const showMinFilters = e => {
        e.preventDefault();
        filter.classList.remove('show-all')
        checkFiltersPendingUpdate()
        setTimeout(()=>{
            showFiltersBtn.classList.remove('hide')
        }, 500);
    };


    hideFilters.forEach(trigger => trigger.addEventListener('click', showMinFilters));
    showFiltersBtn.addEventListener('click', showAllFilters);

    editFilters.forEach(trigger => trigger.addEventListener('click', e => {
        updateFilterChanged(true);
        showAllFilters(e);
    }));
}

const filterSidebar = () => {

    const burgerTrigger = document.getElementById('filterClose');
    const filterTriggers = document.querySelectorAll('.filterOpen');
    if (!burgerTrigger) return;

    burgerTrigger.addEventListener('click', e => {
        e.preventDefault();
        document.body.classList.remove('filter-open');
    });

    filterTriggers.forEach(trigger => {
        trigger.addEventListener('click', e => {
            e.preventDefault()
            document.body.classList.add('filter-open');
        });
    });
}

const onInputChange = () => {
    // On input change trigger updateFilterChanged(true)
}

const setUpdateResults = () => {

    const btnUpdateResultsTriggers = document.querySelectorAll('.btnUpdateResults');

    btnUpdateResultsTriggers.forEach(trigger => {
        trigger.addEventListener('click', e => {
            e.preventDefault();
            updateResults();
        });
    });
}

const setCancelChanges = () => {

    const cancelTriggers = document.querySelectorAll('.cancelChanges');

    cancelTriggers.forEach(trigger => {
        trigger.addEventListener('click', e => {
            e.preventDefault();
            updateFilterChanged(false);
        });
    });
}

const setProviderSwitch = () => {

    const showProviderSwitch = document.querySelectorAll('.showProviderSwitch');
    if(!showProviderSwitch) return;

    const switchChange = document.getElementById('switchChange');
    if(!switchChange) return;

    showProviderSwitch.forEach(trigger => {
        trigger.addEventListener('click', e => {
            e. preventDefault();

            var switchChangeLenderName = document.getElementById('switchChangeLenderName');
            switchChangeLenderName.innerText = e.target.dataset.lenderName;

            var proceedToShowAllLenders = document.getElementById('proceedToShowAllLenders');
            proceedToShowAllLenders.dataset.showAllLendersUrl = e.target.dataset.showAllLendersUrl;

            proceedToShowAllLenders.addEventListener('click', e => {
                e. preventDefault();
                window.location.href = e.target.dataset.showAllLendersUrl;
            });

            var switchChangeStayWithLender = document.getElementById('switchChangeStayWithLender');
            switchChangeStayWithLender.innerText = 'Stay with ' + e.target.dataset.lenderName;

            switchChangeStayWithLender.addEventListener('click', e => {
                e. preventDefault();
                switchChange.style.display = "none";
            });

            switchChange.style.display = "block";
        });
    });
}

const bindMortgageDealPopupLinks = () => {

    const fullDetailsLinks = document.querySelectorAll('.open-full-details-popup');

    fullDetailsLinks.forEach(trigger => {
        trigger.addEventListener('click', e => {
            e.preventDefault();
            loadMortgageDealPopup(trigger);
        });
    });
};

const loadMortgageDealPopup = trigger => {

    if (!trigger) {
        console.warn("The trigger is not defined. Cannot load the product data");
        return;
    }

    if (!trigger.dataset) {
        console.warn("The trigger.dataset is not defined. Cannot load the product data");        
        return;
    }

    if (!trigger.dataset.productQuerystring) {
        console.warn("The trigger.dataset.productQuerystring is not defined. Cannot load the product data");        
        return;
    }

    let getMortgageDealPopupUrl = '/Umbraco/Api/MortgageTableApi/GetMortgageDealPopup?' + trigger.dataset.productQuerystring;

    $.ajax({ 
        url: getMortgageDealPopupUrl,
        type: "GET",
        success: function (data) {

            let dataString = JSON.stringify(data);

            console.log(dataString);


            if (data.IsValidMortgageDeal) {
                openfurtherDetailsPopup(data);
            }
            else {
                console.warn(`Could not find the mortgage deal, the 'IsValidMortgageDeal' was returned as false`);
            }
        },
        error: function (error) {
            console.log(`Error ${error}`);
        }
    });
}

function openfurtherDetailsPopup(data) {

    const furtherDetailsPopup = document.getElementById('furtherDetailsPopup');

    if (!furtherDetailsPopup) {
        console.warn("The popup cannot be found. Cannot load the popup");
        return;
    }

    setfurtherDetailsPopup(data);

    furtherDetailsPopup.style.display = 'block';

    const linkCloseFurtherDetailsPopup = document.getElementById('linkCloseFurtherDetailsPopup');
    linkCloseFurtherDetailsPopup.addEventListener('click', e => {
        e.preventDefault();
        furtherDetailsPopup.style.display = 'none';
    });

    const closeFurtherDetailsPopup = document.getElementById('closeFurtherDetailsPopup');
    closeFurtherDetailsPopup.addEventListener('click', e => {
        e.preventDefault();
        furtherDetailsPopup.style.display = 'none';
    });
}

function setfurtherDetailsPopup(data) {
    let furtherDetailsPopupLogo = document.getElementById('furtherDetailsPopupLogo');
    furtherDetailsPopupLogo.setAttribute('src', data.LogoUrl);
    furtherDetailsPopupLogo.setAttribute('alt', data.LenderName);
    
    let furtherDetailsPopupMonthlyCost = document.getElementById('furtherDetailsPopupMonthlyCost');
    furtherDetailsPopupMonthlyCost.innerText = '£' + data.InitialMonthlyPayment;
    
    let furtherDetailsPopupInitialRate = document.getElementById('furtherDetailsPopupInitialRate');
    furtherDetailsPopupInitialRate.innerText = data.InitialPayRate + '%';
    
    let furtherDetailsPopupDescription = document.getElementById('furtherDetailsPopupDescription');
    furtherDetailsPopupDescription.innerText = data.ProductDescription;
    
    let furtherDetailsPopupApplicationFee = document.getElementById('furtherDetailsPopupApplicationFee');
    furtherDetailsPopupApplicationFee.innerText = data.ApplicationFee;
    
    let furtherDetailsPopupAprc = document.getElementById('furtherDetailsPopupAprc');
    furtherDetailsPopupAprc.innerText = data.AprLenders + '%';
    
    let furtherDetailsPopupBrokerPageUrl = document.getElementById('furtherDetailsPopupBrokerPageUrl');
    furtherDetailsPopupBrokerPageUrl.href = data.MortgageQuotesPageUrl;
    
    let furtherDetailsPopupInitialTermDetails = document.getElementById('furtherDetailsPopupInitialTermDetails');
    furtherDetailsPopupInitialTermDetails.innerText = data.InitialTermText;
    
    let furtherDetailsPopupFinalTerm = document.getElementById('furtherDetailsPopupFinalTerm');
    furtherDetailsPopupFinalTerm.innerText = data.FinalTermText;
    
    let furtherDetailsPopupTotalInterest = document.getElementById('furtherDetailsPopupTotalInterest');
    furtherDetailsPopupTotalInterest.innerText = data.TotalInterest;
    
    let furtherDetailsPopupTotalFees = document.getElementById('furtherDetailsPopupTotalFees');
    furtherDetailsPopupTotalFees.innerText = data.TotalFees;
    
    let furtherDetailsPopupTotalCost = document.getElementById('furtherDetailsPopupTotalCost');
    furtherDetailsPopupTotalCost.innerText = data.TotalPayable;
    
    let furtherDetailsPopupAprcDetails = document.getElementById('furtherDetailsPopupAprcDetails');
    furtherDetailsPopupAprcDetails.innerText = data.AprLenders + '%';
    
    let furtherDetailsPopupRepaymentAmount = document.getElementById('furtherDetailsPopupRepaymentAmount');
    furtherDetailsPopupRepaymentAmount.innerText = data.RepaymentText;
    
    let furtherDetailsPopupMortgageServiceUrl = document.getElementById('furtherDetailsPopupMortgageServiceUrl');
    furtherDetailsPopupMortgageServiceUrl.href = data.MortgageQuotesPageUrl;
    
    let furtherDetailsPopupRedemptionFee = document.getElementById('furtherDetailsPopupRedemptionFee');
    furtherDetailsPopupRedemptionFee.innerText = data.RedemptionFee;
    
    let furtherDetailsPopupArrangementFee = document.getElementById('furtherDetailsPopupArrangementFee');
    furtherDetailsPopupArrangementFee.innerText = data.ArrangementFee;
    
    let furtherDetailsPopupValuationFee = document.getElementById('furtherDetailsPopupValuationFee');
    furtherDetailsPopupValuationFee.innerText = data.ValuationFee;
    
    let furtherDetailsPopupCashback = document.getElementById('furtherDetailsPopupCashback');
    furtherDetailsPopupCashback.innerText = data.Cashback;
    
    let furtherDetailsPopupLegalCosts = document.getElementById('furtherDetailsPopupLegalCosts');
    furtherDetailsPopupLegalCosts.innerText = data.FreeLegals;
    
    let furtherDetailsPopupFreeValuation = document.getElementById('furtherDetailsPopupFreeValuation');
    furtherDetailsPopupFreeValuation.innerText = data.FreeValuation;
    
    let furtherDetailsPopupRepaymentCharges = document.getElementById('furtherDetailsPopupRepaymentCharges');
    furtherDetailsPopupRepaymentCharges.innerText = data.RepaymentChargesText;
    
    let furtherDetailsPopupEarlyRepaymentChargesDate = document.getElementById('furtherDetailsPopupEarlyRepaymentChargesDate');
    furtherDetailsPopupEarlyRepaymentChargesDate.innerText = data.EarlyRepaymentChargesText;
    
    let furtherDetailsPopupEarlyRepaymentChargesValue = document.getElementById('furtherDetailsPopupEarlyRepaymentChargesValue');
    furtherDetailsPopupEarlyRepaymentChargesValue.innerText = data.EarlyRepaymentDateText;
}

const bindShowMoreDeals = () => {

    const showMoreDeals = document.getElementById('showMoreDeals');

    if (!showMoreDeals) {
        console.info('The element with ID = \'showMoreDeals\' was not found');

        return;
    }

    showMoreDeals.addEventListener('click', e => {
        e.preventDefault();
        loadMortgageDeals(showMoreDeals);
    });
};

const loadMortgageDeals = (showMoreDeals) => {

    let productDataString = showMoreDeals.dataset.productQuerystring;
    if (!productDataString) {
        console.warn('The element with ID = ' + showMoreDeals.id + ' does not have a dataset for product querystring. Cannot load the next page');

        return;
    }
    
    let productData = JSON.parse(productDataString);
    productData.PageIndex = productData.PageIndex + 1;

    console.info(`Loading mortgage table rows. Request: ${JSON.stringify(productData)}`);
    
    hideShowMoreDeals();

    $.get('/Umbraco/Api/MortgageTableApi/GetTriageMortgageTablePage',
        productData,
        function (mortgageTablePage) {
            console.info(`Mortgage table rows loaded. Response: ${JSON.stringify(mortgageTablePage)}`);

            if (mortgageTablePage != null) {

                if (mortgageTablePage.HasNextPage) {
                    showShowMoreDeals();

                    scrollToElement('showMoreDeals');
                }

                renderMortgageTableRows(productData, mortgageTablePage);

                bindMortgageDealPopupLinks();

                var showMoreDeals = $('#showMoreDeals');
                showMoreDeals.attr('data-product-querystring', JSON.stringify(productData));
            }
        })
    .error(function (error) {
        console.log(`An error occurred when trying to load the mortgage table page. Error ${error}`);
        
        hideShowMoreDeals();
    });
}

const renderMortgageTableRows = (productData, mortgageTablePage) => {
    console.info(`Displaying page index = ${productData.PageIndex}`);

    if (mortgageTablePage.Rows != null) {
        var mortgageTablePlaceholder = $('#mortgageTablePlaceholder');

        $(mortgageTablePage.Rows).each(function (index) {
            var source = $('#mortgageTableRowTemplate').html();
            var template = Handlebars.compile(source);
            var compiledHtml = $(template(mortgageTablePage.Rows[index]));
            mortgageTablePlaceholder.append(compiledHtml);

            $(compiledHtml).delay(200 * index).fadeIn(200);
        });
    }
};

const hideShowMoreDeals = () => {
    var showMoreDeals = $('#showMoreDeals');
    showMoreDeals.fadeOut(200);
};

const showShowMoreDeals = () => {
    var showMoreDeals = $('#showMoreDeals');
    showMoreDeals.fadeIn(200);
};

const scrollToElement = (elementId) => {
    $('html, body').animate({
        scrollTop: $('#' + elementId).offset().top
    }, 1000);
};

function registerHandlebarsHelpers() {
    //{{#xIf Age ">=" 42}}
    //  Add logic here
    //{{else}}
    //  //  Add logic here
    //{{/xIf}}
    Handlebars.registerHelper('xIf', function (lvalue, operator, rvalue, options) {

        var operators, result;

        if (arguments.length < 3) {
            throw new Error("Handlerbars Helper 'compare' needs 2 parameters");
        }

        if (options === undefined) {
            options = rvalue;
            rvalue = operator;
            operator = "===";
        }

        operators = {
            '==': function (l, r) { return l == r; },
            '===': function (l, r) { return l === r; },
            '!=': function (l, r) { return l != r; },
            '!==': function (l, r) { return l !== r; },
            '<': function (l, r) { return l < r; },
            '>': function (l, r) { return l > r; },
            '<=': function (l, r) { return l <= r; },
            '>=': function (l, r) { return l >= r; },
            'typeof': function (l, r) { return typeof l == r; }
        };

        if (!operators[operator]) {
            throw new Error("'xIf' doesn't know the operator " + operator);
        }

        result = operators[operator](lvalue, rvalue);

        if (result) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    });
};

window.addEventListener('load', setUpdateResults);
window.addEventListener('load', setCancelChanges);
window.addEventListener('load', setProviderSwitch);
window.addEventListener('load', onInputChange);
window.addEventListener('load', filterSidebar);
window.addEventListener('load', createFilterToggles);
window.addEventListener('load', bindMortgageDealPopupLinks);
window.addEventListener('load', registerHandlebarsHelpers);
window.addEventListener('load', bindShowMoreDeals);