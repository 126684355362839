import {cleanMoneyString, formatNumber} from "./Utils";

const createUpdateTargets = () => {

    const inputs = document.querySelectorAll('.update-target');

    inputs.forEach(input => {

        const targetId = input.dataset.targetId;
        const targetIsMoney = input.dataset.targetIsMoney === 'y';
        const targetFromMoney = input.dataset.targetFromMoney === 'y';
        const target = document.getElementById(targetId);
        const targetDummy = document.getElementById(targetId + '_dummy')
        if(!target) return;

        input.addEventListener('input', () => {

            if(targetIsMoney && targetDummy) {
                target.value = input.value
                targetDummy.value = formatNumber(input.value)
            } else if(targetFromMoney) {
                target.value = cleanMoneyString(input.value)
            } else {
                target.value = input.value
            }
        })

    })


}
window.addEventListener('load', createUpdateTargets);
