window.addEventListener('load', () => {
    const navDropdowns = document.querySelectorAll('.main-nav__dropdown')
    navDropdowns.forEach( el => {
        const subNav = el.parentNode.querySelector('.main-nav__sub')
        el.addEventListener('click', e => {
            e.preventDefault();
            toggleNav(el, subNav);
        })
    })

    const burgerTrigger = document.getElementById('menuToggle')
    if (!burgerTrigger) return;

    burgerTrigger.addEventListener('click', e => {
        e.preventDefault()
        document.body.classList.toggle('nav-open')
    })
});



const toggleNav = (link, subMenu) => {
    if(
        !link.classList.contains('main-nav__dropdown--open') &&
        !subMenu.classList.contains('main-nav__sub--open')
    ) {
        closeAll();
        link.classList.add('main-nav__dropdown--open')
        subMenu.classList.add('main-nav__sub--open')
    } else {
        link.classList.remove('main-nav__dropdown--open')
        subMenu.classList.remove('main-nav__sub--open')
    }
}

const closeAll = () => {
    document.querySelectorAll('.main-nav__sub').forEach(el => {
        el.classList.remove('main-nav__sub--open')
    })
    document.querySelectorAll('.main-nav__dropdown').forEach(el => {
        el.classList.remove('main-nav__dropdown--open')
    })
}
