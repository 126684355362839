import {contain} from "./Utils";

const setContainers = () => {

    const container = document.querySelector('.triage__info');
    const child = document.querySelector('.triage__info--child');

    if(!container || !child) return;

    window.addEventListener('scroll', () => {
        child.style.top = contain(child, container, '50%', 'center');
    })
    window.dispatchEvent(new Event('scroll'));
}


window.addEventListener('load', setContainers);
