const setupConditionalInputs = () => {

    const conditionalTriggers = document.querySelectorAll('.input-group-trigger .input-question__option');

    conditionalTriggers.forEach(conditionalTrigger => {

        const showTargetID = conditionalTrigger.dataset.displayGroup;
        const hideTargetID = conditionalTrigger.dataset.hideGroup;

        const showTarget = document.getElementById(showTargetID);
        const hideTarget = document.getElementById(hideTargetID);
        
        if(!showTarget && !hideTarget) {
            return;
        }

        if (conditionalTrigger.classList.contains('active')) {
            handleConditionalTrigger();
        }

        conditionalTrigger.addEventListener("click", () => {
            handleConditionalTrigger();
        });

        function handleConditionalTrigger() {
            if (showTarget) {
                showTarget.style.display = 'block';

                return;
            }

            if (hideTarget) {

                hideTarget.style.display = 'none';

                const activeConditionalOption = hideTarget.querySelector('.input-question__option.active');
                const conditionalHiddenField = hideTarget.querySelector('input[type=hidden]');

                if (activeConditionalOption) {
                    activeConditionalOption.className = 'input-question__option';
                }

                if (!conditionalHiddenField) {
                    return;
                }

                conditionalHiddenField.value = '';
            }
        }
    });
}

window.addEventListener('load', setupConditionalInputs);
