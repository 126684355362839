const registerInputs = () => {

    const classNames = [
        '.input-group input',
        '.input-group select',
        '.input-group text',
        '.input-group .input-question__option',
    ];

    const classList = classNames.reduce((accumulator, currentValue) => accumulator + `, ${currentValue}`)
    const inputs = document.querySelectorAll(classList);

    inputs.forEach(input => input.addEventListener('click', () => {
        const parent = input.closest('.input-group');
        if(!parent) return;
        parent.classList.remove('input-group--error')
    }))

}

window.addEventListener('load', registerInputs);
